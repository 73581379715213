"use strict";

import * as googleMap from "@elements/google-map";
import {find, findIn, findAll} from "@elements/dom-utils"
import {onFind} from "@elements/init-modules-in-scope";
import {onEnterViewPort} from "@elements/viewport-utils";


const isMobile = () => matchMedia('(max-width: 767px)').matches;


export function init () {
    let checkMapConsent = setInterval(function(){
        onFind('.js-google-map', function (mapElement) {
            clearInterval(checkMapConsent);
            let poiVarName = mapElement.dataset.googleMapPois;
            let pois = [];

            if (poiVarName) {
                if (!window['_googleMapPois']) {
                    console.error('_googleMapPois is not set');
                } else if (!window._googleMapPois[poiVarName]) {
                    console.error(poiVarName + ' is not set in _googleMapPois', _googleMapPois);
                } else {
                    pois = _googleMapPois[poiVarName];
                }
            }

            onEnterViewPort(find('.js-google-map'), function (element) {
                console.log("enter viewport");
                let map = googleMap.init({
                    element: mapElement,
                    pois: pois,
                    mapOptions: {
                        center: {lat: 47.076668, lng: 15.421371},
                        zoom: 14,
                        maxZoom: 18,
                        scrollwheel: true,
                        mapTypeControl: false,
                        streetViewControl: false,
                        styles: getMapStyling()
                    },
                    poiStyles: () => createPoiStyles(),
                    infoBoxOptions: () => getGoogleInfoBoxOptions(),
                    clustering: false,
                    clusteringOptions: createClusterStyles(),
                    onActivateMarker: (marker, api) => onActivateMarker(marker, api),
                    onDeactivateMarker: (marker, api) => onDeactivateMarker( marker, api),
                });

                googleMap.getApi(element).then((api) => {
                    api.centerMap();
                });
            }, {
                offset: 0
            });
        });
    }, 250)

}

export function createClusterStyles() {
    return {
        default: {
            styles: [{
                height: 53,
                url: "/static/build/img/poi.svg",
                width: 48,
                textSize: 1,
                textColor: "#FFF"
            }]
        }
    }
}

export function createPoiStyles() {
    return {
        main: {
            default: {
                url: '/static/build/img/poi.svg',
                size: new google.maps.Size(49, 60),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(24, 60)
            },
            active: {
                url: '/static/build/img/poi.svg',
                size: new google.maps.Size(49, 60),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(24, 60)
            }
        },
        stores: {
            default: {
                url: '/static/build/img/poi.svg',
                size: new google.maps.Size(49, 60),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(24, 60)
            },
            active: {
                url: '/static/build/img/poi.svg',
                size: new google.maps.Size(49, 60),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(24, 60)
            }
        }
    }
}
export function onActivateMarker(marker, api) {
    console.log(marker);
    if(marker.detailInfoBoxUrl) {
        let infoBoxPromise = api.showInfoBoxByMarker(
            marker,
            ` <div class="loading-spinner" aria-label="loading...">
                    <div class="loading-spinner__item loading-spinner__item--1"></div>
                    <div class="loading-spinner__item loading-spinner__item--2"></div>
                    <div class="loading-spinner__item loading-spinner__item--3"></div>
                </div>`
        );

        let contentPromise = fetch(marker.detailInfoBoxUrl, {
            method: 'GET',
            headers: {"content-type": "application/json"}
        }).then((res) => {
            return res.json()
        });

        Promise.all([infoBoxPromise, contentPromise]).then(([infoBox, response]) => {
            infoBox.setContent(response.html);
        });
    }
}

export function onDeactivateMarker(marker, api) {
    api.closeInfoBoxByMarker(marker);
}

export function getMapStyling() {
    console.log('map styling');
    return  [
        {
            "featureType": "administrative",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#444444"
                }
            ]
        },
        {
            "featureType": "landscape",
            "elementType": "all",
            "stylers": [
                {
                    "color": "#ececec"
                }
            ]
        },
        {
            "featureType": "landscape.natural.landcover",
            "elementType": "all",
            "stylers": [
                {
                    "saturation": "-1"
                },
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "landscape.natural.landcover",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "visibility": "simplified"
                }
            ]
        },
        {
            "featureType": "landscape.natural.terrain",
            "elementType": "geometry",
            "stylers": [
                {
                    "saturation": "30"
                },
                {
                    "lightness": "-29"
                },
                {
                    "color": "#c9c9c9"
                },
                {
                    "weight": "1.00"
                }
            ]
        },
        {
            "featureType": "poi",
            "elementType": "all",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "road",
            "elementType": "all",
            "stylers": [
                {
                    "saturation": -100
                },
                {
                    "lightness": 45
                }
            ]
        },
        {
            "featureType": "road",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#d6d6d6"
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "all",
            "stylers": [
                {
                    "visibility": "simplified"
                }
            ]
        },
        {
            "featureType": "road.arterial",
            "elementType": "labels.icon",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "transit",
            "elementType": "all",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "water",
            "elementType": "all",
            "stylers": [
                {
                    "color": "#ffffff"
                },
                {
                    "visibility": "on"
                }
            ]
        }
    ];
}

export function getGoogleInfoBoxOptions(){
    let width = isMobile() ? "300px" : "384px";
    let offsetX = isMobile() ? -150 : 0;
    let offsetY = isMobile() ? -40 : -20;

    return {
        zIndex:-1,
        pixelOffset: new google.maps.Size(offsetX, offsetY),
        boxStyle: {width: width, padding: "0"},
        boxClass: "info-box",
        closeBoxURL: ""
    };
}
